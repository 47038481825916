/* src/App.css */
.App {
  text-align: center;
  font-family: Arial, sans-serif;
}

/* THIS IS FOR THE HEADER PAGE*/
/* Header Styling */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  background-color: #282c34;
  position: relative;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  height: 60px;
  margin-right: 20px;
}

.site-name {
  color: white;
  font-size: 1.5em;
}

/* Default Navigation Menu */
nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

nav ul li {
  margin-right: 15px;
}

nav ul li a {
  color: white;
  text-decoration: none;
  padding: 15px 20px;
  font-size: 1.5em;
  transition: background-color 0.3s, color 0.3s;
}

nav ul li a:hover {
  background-color: red;
  color: white;
  border-radius: 4px;
}

/* Mobile Menu Styling */
.mobile-menu-icon {
  display: none;
  font-size: 2em;
  color: white;
  background: none;
  border: none;
  cursor: pointer;
}

/* Hide nav on mobile by default */
.nav {
  display: flex;
}

@media (max-width: 768px) {
  /* Show hamburger icon on mobile */
  .mobile-menu-icon {
    display: block;
  }

  /* Hide the nav by default */
  .nav {
    display: none;
    position: absolute;
    top: 70px;
    right: 20px;
    background-color: #282c34;
    border-radius: 8px;
    width: 200px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }

  /* Show the nav when the menu is open */
  .nav.open {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  .nav ul {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .nav ul li {
    margin: 10px 0;
    text-align: center;
  }

  .nav ul li a {
    display: block;
    padding: 15px;
    font-size: 1.5em;
  }
  /* Mobile Menu Icon Styling */
.mobile-menu-icon {
  color: white; /* Ensure the icon is white */
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1000; /* Ensure it is above other elements */
}

/* Add transition for smoother effect */
.nav {
  transition: all 0.3s ease-in-out;
}

/* Ensure the menu overlay doesn't interfere with the header text */
.nav.open {
  z-index: 999; /* Place it above other elements */
}

}


/* ---------HOME CONTAINER--------- */
/* Home container styling */
.home-container {
  width: 100%;
  overflow: hidden;
}

/* Background video section wrapper */
.background-section {
  position: fixed;
  width: 100%;
  height: 400vh; /* Extended height to ensure long background video coverage */
}

/* Background video styling */
.background-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh; /* Keep the video height at the viewport height */
  object-fit: cover;
  z-index: -3; /* Keeps the video behind the overlay and other content */
  pointer-events: none; /* Prevents interaction */
}

/* Overlay styling */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 140%; /* Keep overlay fixed over the video */
  background-color: rgba(0, 0, 0, 0.6); /* Adds a dark overlay for readability */
  z-index: -2;
}

/* Main content styling within the background section */
.content {
  position: relative;
  z-index: 1;
  text-align: center;
  color: #fff;
  padding-top: 20vh; /* Start content down from top */
  padding-bottom: 40vh; /* Adds space at the bottom of the section to push other sections further down */
}

/* Main heading styling */
.main-heading {
  font-size: 3rem;
  font-weight: bold;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
}

.introduction-text {
  max-width: 800px;
  margin: 20px auto;
  padding: 10px;
  font-size: 1.2rem;
  color: #fff;
  border-radius: 5px;
}

/* "Get Started" button styling */
.learn-more-btn {
  display: inline-block;
  padding: 10px 20px;
  background-color: #ff6347;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1rem;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.learn-more-btn:hover {
  background-color: #ff0000;
}

/* Floating button styling */
.floating-button-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.floating-button {
  padding: 10px 20px;
  background-color: #ff6347;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 200px;
  height: 50px;
  font-size: 1.1rem;
  font-weight: bold;
}

.floating-button:hover {
  background-color: #ff4500;
}

/* YouTube section styling */
.youtube-section {
  padding: 60px 20px; /* Padding around the section */
  background-color: #ffffff; /* White background for contrast */
  text-align: center;
  color: #333;
  width: 100%;
  z-index: 1;
}

.youtube-title {
  font-size: 2rem;
  margin-bottom: 20px;
}

.youtube-video iframe {
  max-width: 100%;
  width: 560px;
  height: 315px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
/* Stats Section within Video Container */
.stats-section {
  margin-top: 30px; /* Adds more space below the "Get Started" button */
  text-align: center;
  color: #fff; /* White text to match the video overlay */
}

.stats-intro {
  font-size: 1.5rem; /* Larger font size for the introductory text */
  margin-bottom: 30px;
  color: #ddd; /* Slightly lighter than white for contrast */
}

.stats-container {
  display: flex;
  justify-content: center;
  gap: 60px; /* Increases space between stats items */
  flex-wrap: wrap; /* Allows wrapping on smaller screens */
}

.stat {
  text-align: center;
  max-width: 250px; /* Increases width for larger stats */
}

.stat-number {
  font-size: 4.5rem; /* Larger font size for numbers */
  font-weight: bold;
  color: #ff6347; /* Red color for the numbers */
  margin-bottom: 15px; /* Increases spacing below numbers */
  text-shadow: 2px 2px 0 #fff, -2px -2px 0 #fff, 2px -2px 0 #fff, -2px 2px 0 #fff; /* White outline */
}

.stat-label {
  font-size: 1.1rem; /* Larger font size for labels */
  text-transform: uppercase;
  letter-spacing: 1px;
  color: white; /* Red color for the labels */
  border-top: 2px solid #ff6347; /* Thicker red line for separation */
  padding-top: 15px; /* Increases spacing above labels */
}

/* Responsive Styling for Smaller Screens */
@media (max-width: 768px) {
  .stats-container {
    flex-direction: column; /* Stacks stats vertically on smaller screens */
    gap: 30px; /* Adjust spacing between items for smaller screens */
  }

  .stat {
    max-width: 100%; /* Full width on mobile */
  }
}

@media (max-width: 768px){
  .overlay{
    height:170%;
  }
  .youtube-video iframe {
    margin-right: 5pt;
    margin-left: 5pt;
  }
}

/* FOR PROGRAMS */
/* src/components/Programs.css */
.programs-container {
  padding: 40px 20px;
  text-align: center;
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column; /* Align title and grid vertically */
  align-items: center;
  justify-content: center; /* Centers the content horizontally */
}
.programs-title {
  font-size: 2rem; /* Larger font for emphasis */
  color: #333;
  margin-bottom: 10px; /* Space below the title */
  text-align: center;
}
.program-subtitle {
  font-size: 0.9rem; /* Slightly smaller than the description */
  color: #555; /* Dark gray for better visibility */
  margin-bottom: 5px; /* Space between subtitle and description */
  font-style: italic;
}
.programs-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Four columns */
  gap: 20px; /* Space between items */
  max-width: 1500px; /* Constrain the grid width */
  width: 100%;
  margin-top: 20px;
}

.program-card {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  text-align: left;
}

.program-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.program-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 15px;
}

.program-card h2 {
  font-size: 1.5rem;
  margin: 10px 0;
  color: #333;
  height: 60px; /* Fixed height for consistent alignment */
}

.program-card p {
  font-size: 1rem;
  color: #666;
  line-height: 1.5;
  min-height: 60px; /* Ensures consistent space for descriptions */
  margin-top: 0;
}

/* Responsive layout for tablets */
@media (max-width: 992px) {
  .programs-grid {
    grid-template-columns: repeat(2, 1fr); /* Two columns on medium screens */
  }
}

/* Responsive layout for mobile */
@media (max-width: 576px) {
  .programs-grid {
    grid-template-columns: 1fr; /* One column on small screens */
  }
}
/* AWARDS STUFF */

.awards-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  width: 100%;
  overflow: hidden;
}

.background-video-awards {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.overlayAwards {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.awards-content {
  position: relative;
  z-index: 1;
  color: #fff;
  text-align: center;
}

.awards-title {
  font-size: 2.5rem;
  margin-bottom: 30px;
}

.awards-column {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 900px;
  margin: 0 auto;
}

/* Styling for each award box */
.award-box {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  padding: 20px;
    margin: 30px 0; /* Adds more space between the boxes */
  text-align: left;
  color: #333;
  width: 100%; /* Full width within the container */
}

.award-box h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  text-align: center;
}

.award-box ul {
  list-style-type: disc;
  padding-left: 20px;
}

.award-box li {
  font-size: 1rem;
  margin-bottom: 8px;
}
/* ABOUT PAGE */ 
/* Timeline Section */
.timeline-section {
  margin: 40px 0;
  text-align: center;
}

.timeline-section h2 {
  font-size: 1.8em;
  margin-bottom: 10px;
  color: #333;
}

.timeline-section h3 {
  font-size: 1.4em;
  margin-bottom: 20px;
  color: #555;
}

.timeline {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  gap: 20px;
  align-items: flex-start;
}

.timeline-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px; /* Increases spacing between elements */
}

.timeline-item {
  background-color: #ff4c4c; /* Red box color */
  border-radius: 8px;
  padding: 10px 20px;
  text-align: center;
  width: 120px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: white; /* White text for better contrast */
  font-weight: bold;
  font-size: 1.2em;
  position: relative;
}

/* Arrow Style */
.timeline-item::after {
  content: '';
  position: absolute;
  top: calc(100% + 15px); /* Positions the arrow further from the box */
  left: 50%;
  transform: translateX(-50%);
  width: 2px; /* Width of the arrow line */
  height: 30px; /* Length of the arrow line */
  background-color: black; /* Black arrow line color */
}

.timeline-item::before {
  content: '';
  position: absolute;
  top: calc(100% + 45px); /* Positions the arrowhead below the line */
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 15px solid black; /* Black arrowhead color */
}

/* Name Below the Arrow */
.timeline-coach {
  margin-top: 60px; /* Space between the arrow and the name */
  font-size: 1em;
  color: #333; /* Neutral text color */
  font-weight: bold;
  text-align: center;
}

.container {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.row {
  display: flex;
  justify-content: center; /* Centers the row */
  gap: 20px; /* Ensures equal spacing between boxes */
  margin-bottom: 20px;
}

.person-box {
  width: 350px; /* Fixed width for uniform size */
  min-height: 570px; /* Adjusted min-height to accommodate title */
  border: 1px solid #ddd;
  padding: 15px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.photo {
  width: 200px; /* Sets the desired circular frame size */
  height: 200px; /* Matches width to create a square area */
  border-radius: 50%; /* Makes the photo appear circular */
  object-fit: cover; /* Crops the image to fill the circle without stretching */
  object-position: center; /* Centers the image within the circle */
  margin-bottom: 10px;
}

.name {
  font-weight: bold;
  font-size: 1.1em;
  margin-bottom: 5px;
}

.title {
  font-size: 0.9em;
  font-style: italic;
  color: #777;
  margin-bottom: 8px;
}

.description {
  color: #555;
  text-align: center;
  font-size: 0.9em;
  line-height: 1.4;
  max-height: 4.2em; /* Limits height for approximately 3 lines of text */
  overflow: hide; /* Hides overflow text */
  display: -webkit-box; /* Required for line clamping */

}
/* Mobile View: Stacked Layout */
@media (max-width: 768px) {
  .row {
    flex-direction: column; /* Stacks boxes vertically */
    align-items: center; /* Centers items */
  }

  .person-box {
    width: 90%; /* Takes most of the screen width on mobile */
    margin-bottom: 20px; /* Adds space between stacked items */
  }

  .photo {
    width: 150px; /* Scales down photo for smaller screens */
    height: 150px;
  }

  .name {
    font-size: 1em; /* Adjust font size for smaller screens */
  }

  .title {
    font-size: 0.9em;
  }

  .description {
    font-size: 0.85em; /* Reduces description font size for smaller screens */
    line-height: 1.4;
    margin-top: 10px; /* Ensures separation between sections */
    display: block; /* Text remains fully visible */
  }
  .timeline {
    flex-wrap: wrap; /* Allows timeline items to wrap on smaller screens */
    justify-content: center; /* Center items when wrapping */
    gap: 20px; /* Adjust gap for better spacing */
  }

  .timeline-item {
    width: 100px; /* Adjusts width for smaller screens */
    padding: 8px 15px; /* Smaller padding for compact layout */
  }

  .timeline-coach {
    font-size: 0.9em; /* Slightly smaller font for names */
    margin-top: 50px; /* Adjusts spacing between arrow and name */
  }

  .timeline-section h2 {
    font-size: 1.5em; /* Smaller headline font size */
  }

  .timeline-section h3 {
    font-size: 1.2em; /* Smaller sub-headline font size */
  }
}

@media (max-width: 480px) {
  .photo {
    width: 120px; /* Further reduction for very small screens */
    height: 120px;
  }

  .name {
    font-size: 0.9em;
  }

  .title {
    font-size: 0.8em;
  }

  .description {
    font-size: 0.8em;
    line-height: 1.3;
    margin-top: 10px;
    display: block; /* Ensures text remains visible */
  }
}

/*GALLERY CSS */
/* General Styles */
.gallery-page {
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.gallery-title {
  font-size: 2.5rem;
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.gallery-intro {
  text-align: center;
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 40px;
}

/* Section Styles */
.gallery-section {
  margin-bottom: 60px;
}

.section-title {
  font-size: 2rem;
  color: #0073e6;
  margin-bottom: 10px;
  text-align: center;
}

.section-description {
  font-size: 1.1rem;
  color: #555;
  text-align: center;
  margin-bottom: 20px;
}

/* Horizontal Scrolling */
.scroll-container {
  position: relative;
  display: flex;
  align-items: center;
}

.image-scroll-container {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  gap: 20px;
  padding-bottom: 10px;
  flex: 1;
}

.image-scroll-container::-webkit-scrollbar {
  height: 8px;
}

.image-scroll-container::-webkit-scrollbar-thumb {
  background-color: #0073e6;
  border-radius: 10px;
}

.image-container {
  flex: 0 0 auto;
  width: 300px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  cursor: pointer; /* Add cursor pointer */
}

.image-container:hover {
  transform: scale(1.05);
}

.gallery-image {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 10px;
}

/* Scroll Buttons */
.scroll-button {
  background-color: #0073e6;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.scroll-button:hover {
  background-color: #005bb5;
}

.scroll-button.left {
  margin-right: 10px;
}

.scroll-button.right {
  margin-left: 10px;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  background-color: transparent;
  border-radius: 10px;
  max-width: 60%;
  max-height: 60%;
  padding: 0;
}

.modal-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #0073e6;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 12px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.modal-close-button:hover {
  background-color: #005bb5;
}
@media (max-width: 992px) {
  .modal-content {
    max-width: 100%;
    max-height: 100%;
  }
  .modal-close-button {
    position: relative;
    top: 5px;
    right: 5px;
  }
}

/* THIS IS FOR THE CONTACTS PAGE */
/* General Styling */
/* Form Styling */
.contact-page {
  padding: 30px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Headings */
.contact-page h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

.contact-page p {
  font-size: 1.1rem;
  color: #666;
  line-height: 1.6;
  text-align: center;
  margin-bottom: 30px;
}

/* Flex container for two columns */
.contact-container {
  display: flex;
  gap: 30px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.contact-direct,
.contact-form {
  flex: 1;
  min-width: 300px;
  background-color: #fff;
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}

/* Contact List */
.contact-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.contact-list li {
  cursor: pointer;
  color: #0073e6;
  font-size: 1.2rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 20px;
  margin: 10px 0;
  border-radius: 6px;
  background-color: #f0f8ff;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.contact-list li:hover {
  background-color: #0073e6;
  color: white;
}

.contact-list li:hover .contact-icon {
  color: white;
}

.contact-list li a {
  color: inherit; /* Inherit the color from the parent element */
  text-decoration: none; /* Remove the underline */
}

.contact-list li a:hover {
  color: white; /* Change text to white on hover */
  text-decoration: none; /* Ensure underline is not added on hover */
}

/* Contact Icons */
.contact-icon {
  font-size: 1.6rem;
  color: #0073e6;
  flex-shrink: 0;
  transition: color 0.3s ease;
}


/* Form Styling */
.contact-form form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-group label {
  font-weight: bold;
  font-size: 1rem;
}

.form-group input,
.form-group textarea {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #0073e6;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 115, 230, 0.5);
}

.submit-button {
  padding: 12px 20px;
  font-size: 1rem;
  color: white;
  background-color: #0073e6;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #005bb5;
}
.form-success {
  color: green;
  font-size: 1.2rem;
  text-align: center;
  margin-top: 20px;
}
/* Modal Styling */
.wechat-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.wechat-thumbnail {
  width: 300px;
  height: auto;
  border-radius: 8px;
  margin-top: 20px;
}

.modal-close-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  background-color: #0073e6;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.modal-close-button:hover {
  background-color: #005bb5;
}

/*FOOTER*/
/* Footer Link Styling */
.footer-link {
  text-decoration: none;
  color: red;
  transition: color 0.3s ease;
}

.footer-link:hover {
  color: white;
}
/* THIS IS FOR BLOG*/
/* Blog Section Styling */
.blog-container {
  padding: 50px 20px;
  max-width: 1200px;
  margin: auto;
  text-align: center;
}

.blog-container h1 {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #333;
}

/* Blog Grid */
.blog-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  justify-items: center;
}

/* Blog Card */
.blog-card {
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.blog-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Blog Image */
.blog-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

/* Blog Content */
.blog-content {
  padding: 20px;
  text-align: left;
}

.blog-title {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
}

.blog-date {
  font-size: 0.9rem;
  color: #888;
  margin-bottom: 15px;
  display: block;
}

.blog-description {
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
}

/* Read More Link */
.blog-read-more {
  font-size: 1rem;
  color: #007bff;
  font-weight: bold;
  text-decoration: none;
  transition: color 0.2s ease;
}

.blog-read-more:hover {
  color: #0056b3;
}

/* Other footer styles remain the same */


/* General Footer Styling */
.footer {
  background-color: black;
  color: white;
  padding: 40px 20px;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 900px;
  gap: 40px;
}

.contact-section {
  flex: 1;
  text-align: left;
}

.contact-section p {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
  font-size: 1rem;
}

.contact-icon {
  color: red;
  font-size: 1.2rem;
}

.social-section {
  flex: 1;
  text-align: right;
}

.social-section h2 {
  color: red;
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.social-icons {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}

.social-link {
  text-decoration: none;
  color: white;
  font-size: 1.5rem;
  transition: color 0.3s ease, transform 0.3s ease;
}

.social-link:hover {
  color: red;
  transform: scale(1.1);
}

.icon {
  font-size: 2rem;
}

.footer-text {
  font-size: 0.9rem;
  line-height: 1.6;
  text-align: center;
}

.footer-text p {
  margin: 0;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 20px;
  }

  .contact-section,
  .social-section {
    text-align: center;
  }

  .social-icons {
    justify-content: center;
  }
}
